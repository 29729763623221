
import { IDisposable } from 'vs/base/common/lifecycle';
import { AbstractCodeEditorService } from 'vs/editor/browser/services/abstractCodeEditorService';
import { IThemeService } from 'vs/platform/theme/common/themeService';

export class GlobalStyleSheet {
	private readonly _styleSheet: HTMLStyleElement;

	constructor(styleSheet: HTMLStyleElement) {
		this._styleSheet = styleSheet;
	}
}

export abstract class CodeEditorServiceImpl extends AbstractCodeEditorService {

	private _globalStyleSheet: GlobalStyleSheet | null;
	private readonly _decorationOptionProviders = new Map<string, IModelDecorationOptionsProvider>();
	private readonly _themeService: IThemeService;

	constructor(
		styleSheet: GlobalStyleSheet | null,
		@IThemeService themeService: IThemeService,
	) {
		super();
		this._globalStyleSheet = styleSheet ? styleSheet : null;
		this._themeService = themeService;
	}

	public removeDecorationType(key: string): void {
		const provider = this._decorationOptionProviders.get(key);
		if (provider) {
			provider.refCount--;
			if (provider.refCount <= 0) {
				this._decorationOptionProviders.delete(key);
				provider.dispose();
				this.listCodeEditors().forEach((ed) => ed.removeDecorations(key));
			}
		}
	}
}

interface IModelDecorationOptionsProvider extends IDisposable {
	refCount: number;
}
